<template>
<div class="scroll-holder">
    <div ref="t" class="scroll-animation">
        <slot />
    </div>
</div>
</template>

<script lang="ts">
// TODO: How should we handle cases where Nuxt wants Browser types in both types and props?
// eslint-disable-next-line no-redeclare, @typescript-eslint/ban-types
// type HTMLElement = Object
const HTMLElement = {}

export default defineNuxtComponent({
    components: {
    },

    props: {
        action: {
            type: String,
            default: 'slide-fade-right'
        },
        anchor: {
            type: HTMLElement || undefined,
            default: undefined
        },
        anchorPoint: {
            type: String,
            default: 'middle'
        },
        appearDuration: {
            type: Number,
            default: 1
        },
        disappearDuration: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            isVisible: false
        }
    },

    computed: {
    },

    mounted() {
        // this.checkScroll()
        this.applyInitialState()
        window.addEventListener('scroll', this.handleScroll)
    },

    unmounted() {
        window.removeEventListener('scroll', this.handleScroll)
    },

    methods: {
        // eslint-disable-next-line complexity
        checkScroll() {
            const element = this.$refs.t as HTMLElement
            const anchor = this.anchor || element
            if (!anchor || !element || element.nodeType !== 1) {
                return
            }

            const viewport = document.documentElement
            const rect = anchor.getBoundingClientRect()

            if (!rect) return

            let triggerPoint = rect.top
            if (this.anchorPoint === 'middle') {
                triggerPoint = rect.top + rect.height / 2
            } else if (this.anchorPoint === 'bottom') {
                triggerPoint = rect.bottom
            }

            const visible = rect.bottom >= 0 && rect.top <= viewport.clientHeight
            if (!this.isVisible) {
                if (triggerPoint >= 0 && triggerPoint <= viewport.clientHeight) {
                    this.animateOn(element)
                }
            } else if (!visible) {
                this.animateOff(element)
                this.isVisible = false
                element.classList.add(`${this.action}-off`)
            }
        },

        animateOn(el: HTMLElement) {
            this.setTransitionDuration(el, this.appearDuration)
            this.isVisible = true
            el.classList.remove(`${this.action}-off`)
            el.classList.add(`${this.action}-on`)
        },

        animateOff(el: HTMLElement) {
            this.setTransitionDuration(el, this.disappearDuration)
            this.isVisible = true
            el.classList.remove(`${this.action}-on`)
            el.classList.add(`${this.action}-off`)
        },

        setTransitionDuration(el: HTMLElement, duration = 1.0) {
            if (duration === 0) {
                el.style.transition = 'none'
            }
            el.style.transition = `all ${duration}s ease-out`
        },

        handleScroll() {
            this.checkScroll()
        },

        applyInitialState() {
            const initialState = `${this.action}-off`;
            (this.$refs.t as HTMLElement).classList.add(initialState)
        }
    }
})

</script>

<style lang="scss">
@use "$styles/kit.scss" as *;

.scroll-holder {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.scroll-animation {
    position: relative;
    height: 100%;
    width: 100%;
}

.slide-fade-right-off {
    opacity: 0;
    transform: translateX(100%);
}

.slide-fade-bottom-off {
    opacity: 0;
    transform: translateY(100%);
}

.slide-fade-left-off {
    opacity: 0;
    transform: translateX(-100%);
}
</style>
